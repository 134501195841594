<template lang="html">
  <div class="event">
    <navbar></navbar>
    <v-layout class="" justify-space-around  wrap>
      <v-flex class="normal py-2" xs12 sm9>
        <v-layout  wrap>
          <v-flex xs12 sm8 v-if="myEvent.img!=undefined">
            <v-img :src="baseUrl+myEvent.img[0].id" :alt="myEvent.name" width="100%" aspect-ratio="1.7721519"></v-img>
          </v-flex>
          <v-flex xs12 sm4 pl-4 pr-3 pt-4>
            <v-layout  wrap>

              <span class="month">{{month}}</span>
            </v-layout>
            <v-layout  wrap>
              <span class="date">{{date}}</span>
            </v-layout>

            <v-layout  wrap py-5>
              <div class="title">
                  {{myEvent.title}}
              </div>
            </v-layout>
            <v-layout  wrap pb-3>
              <div class="">
                  {{myEvent.sdesc}}
              </div>
            </v-layout>
            <v-layout  wrap justify-end align-end v-if="priceRange.min!=priceRange.max">
                {{priceRange.min}}TW$ - {{priceRange.max}}TW$
            </v-layout>
            <v-layout  wrap justify-end align-end v-else>
                {{priceRange.min}}TW$

            </v-layout>
          </v-flex>
        </v-layout>
        <v-divider></v-divider>
        <v-layout class="py-1"  wrap>
          <v-spacer></v-spacer>
          <v-btn color="secondary" :href="'/eventMgmt/'+this.$route.params.id" v-if="this.user.roleValue>22">Manage</v-btn>
          <v-btn color="primary" :disable="disable" flat v-if="user.roleValue>50" @click="duplicate()">Duplicate</v-btn>
          <v-btn color="secondary" :href="'/attendees/'+this.$route.params.id" v-if="this.user.roleValue>22">Guest List</v-btn>
          <v-btn color="#ff4000" dark :href="'/checking/'+this.$route.params.id" v-if="this.user.roleValue>22">Check Tickets</v-btn>
          <v-btn color="primary" id="allTickets" @click="showTickets">Tickets</v-btn>
        </v-layout>
        <v-divider></v-divider>
        <v-layout  wrap>
          <v-flex xs12 sm8 class="pa-2">
            <h2>{{myEvent.title}}</h2>
            <h3>Description</h3>
            <v-layout  wrap>
                <v-flex xs12 v-for="(el,index) in textbox" :key="index">
                  {{el}}
                  <br>

                  <!-- <v-textarea :value="myEvent.description"  auto-grow >
                  </v-textarea> -->
                </v-flex>
                  <!-- {{myEvent.description}} -->
            </v-layout>
          </v-flex>
          <v-flex xs12 sm4 class="pa-2">
            <h3>Date & Time</h3>
            <v-layout  wrap>
              From: {{myEvent.start | longDate}}
              Till: {{myEvent.end | longDate}}
            </v-layout>
            <h3>Location</h3>
            <v-layout  wrap>
              {{myEvent.location}}
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <SelectTickets :show="show" @close="show=false" :tickets="myEvent.tickets" :sold="soldTickets" @confirm="confirm"></SelectTickets>
  </div>
</template>

<script>
import config from '../../../config'
import firebase from '../../../FirebaseApp'
import SelectTickets from './SelectTickets.vue'
import moment from 'moment'
import axios from 'axios'
import {
  mapGetters,
} from 'vuex'
import * as types from '../../../store/types'


export default {
  firebase() {
    return {
      myEvent: {
        source:firebase.database().ref('events/'+this.$route.params.id),
        asObject:true,
        readyCallback:function(){
            // console.log('got it');
            // console.log(this.myEvent.description);
        }
      },
      soldTickets:{
        source:firebase.database().ref('tickets').orderByChild('eventId').equalTo(this.$route.params.id),
        asObject:true,
        readyCallback:function(){
          // console.log('got it');
        }
      }
    }
  },
  data: () => ({
    show:false,
    disable:false
  }),

  methods: {
    showTickets(){
        this.show=true
        dataLayer.push({
          event:'seeTickets',
        })
    },
    duplicate() {
      this.disable=true
      console.log(this.myEvent, this.user);
      let newEvent={
        description:this.myEvent.description,
        end:moment().add(1,'M').format(),
        img:this.myEvent.img,
        location:this.myEvent.location,
        sdesc:this.myEvent.sdesc,
        start:moment().add(1,'M').format(),
        title:this.myEvent.title
      }
      console.log('New event: ', newEvent);
      axios.post('/events.json?auth='+this.$store.state.auth.idToken, newEvent)
      .then(res=>{
        console.log('success', res.data);
        this.$router.push('/addEvent?id='+res.data.name)
      })
    },
    confirm(type){
      //check if user has already ticket for this event :)
      let query=""
      if (type.number>1){
        query='?n='+type.number
      }
      axios.get('tickets.json?orderBy="type"&equalTo="'+type['.key']+'"')
      .then(res=>{
        if ((Object.keys(res.data).length+type.number)<=this.myEvent.tickets[type['.key']].q){
          dataLayer.push({
            event:"addEventToCart",
          })
          this.$router.push('/EventSummary/'+this.$route.params.id+'/'+type['.key']+query)
        } else{
          alert('This item has been sold out!!!')
        }
      })
    }
  },
  computed:{
    ...mapGetters({
      user: types.GET_USER
    }),
    baseUrl(){
      return config.PHOTOURL
    },
    month(){
      return moment.tz(this.myEvent.start,"Asia/Taipei").format("MMM")
    },
    date(){
      return moment.tz(this.myEvent.start,"Asia/Taipei").format("DD")
    },
    setPrices(){

    },
    priceRange(){
      let min=null
      let max=0
      for (var ticket in this.myEvent.tickets) {
        if (this.myEvent.tickets.hasOwnProperty(ticket)) {
          if (min==null || this.myEvent.tickets[ticket].price<min){
            min=this.myEvent.tickets[ticket].price
          }
          if (this.myEvent.tickets[ticket].price>max){
            max=this.myEvent.tickets[ticket].price
          }
        }
      }
      return {min:min,max:max}
    },
    maxPrice(){
      let t=0
      for (var ticket in this.myEvent.tickets) {
        if (this.myEvent.tickets.hasOwnProperty(ticket)) {
          if (this.myEvent.tickets[ticket].price>t){
            t=this.myEvent.tickets[ticket].price
          }
        }
      }
    },
    textbox(){
      if(this.myEvent.description!=undefined){
        return this.myEvent.description.split('\n')
      } else {
        return ""
      }

    }
  },
  filters:{
    longDate(t){
      return moment.tz(t,'Asia/Taipei').format('ddd, LLL')
    }
  },
  created() {
    //do something after creating vue instance
    dataLayer.push({
      eventId:this.$route.params.id,
    })

  },
  components:{
    SelectTickets
  }

}
</script>

<style lang="scss" scoped>
.event{
  background-color: rgba(0,0,0,0.05)
}
.month{
  color:red;
  font-size: 1.2em
}
.date{
  font-size: 1.3em;
  font-weight: bolder;
}
.text{
  color:rgba(0,0,0,.78)!important
}
.normal{
  background-color:white;
}
</style>

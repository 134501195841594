<template lang="html">
<div class="">
  <navbar></navbar>
  <v-layout  wrap justify-space-around class="mt-2">
    <v-flex xs12 sm11 md10>
      <v-layout  wrap v-if="now.length>0">
        <v-toolbar>
          <v-toolbar-title>Happening Now</v-toolbar-title>
        </v-toolbar>
        <v-flex xs12 sm6 md4 v-for="(myEvent, index) in now" :key="myEvent['.key']+'_'+index">
            <EventCard :myEvent="myEvent" :ticket="myEvent.myTicket" :user="user" :past="false" ></EventCard>
          </v-flex>
      </v-layout>
      <v-layout  wrap>
        <v-toolbar>
          <v-toolbar-title>Upcoming Events</v-toolbar-title>
        </v-toolbar>
        <v-flex xs12 sm6 md4 v-for="(myEvent, index) in upcoming" :key="myEvent['.key']+'_'+index">
          <EventCard :myEvent="myEvent" :ticket="myEvent.myTicket" :user="user"></EventCard>
        </v-flex>
      </v-layout>
    <v-layout  wrap>
      <v-toolbar>
        <v-toolbar-title>Past</v-toolbar-title>
      </v-toolbar>
      <v-flex xs12 sm6 md4 v-for="(myEvent, index) in past" :key="myEvent['.key']+'_'+index">
          <EventCard :myEvent="myEvent" :user="user" :ticket="myEvent.myTicket" :past="true" ></EventCard>
      </v-flex>
    </v-layout>

  </v-flex>
</v-layout>

</div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import CardPayment from '../../shared/CardPayment.vue'
import AWSSES from '../../../aws-ses'
import config from '../../../config'
import firebase from '../../../FirebaseApp'
import {
  mapGetters,
} from 'vuex'
import * as types from '../../../store/types'
import EventCard from '../allEvents/EventCard.vue'
import EventList from '../allEvents/EventList.vue'

export default {
  data: () => ({
    // events:[],
    show:false,
    past:[],
    now:[],
    upcoming:[]
  }),
  firebase() {
    return {
      events: {
        source:firebase.database().ref('events/'+this.$route.params.id),
        asObject:true,
        readyCallback:function(){
          console.log('got it');
        }
      },
      myTickets:{
        source:firebase.database().ref('tickets').orderByChild('owner').equalTo(this.user.id),
        readyCallback:function(){
          for (var i = 0; i < this.myTickets.length; i++) {
            axios.get('events/'+this.myTickets[i].eventId+'.json')
            .then(res=>{
              console.log(res)
              let t=res.data
              let index = res.request.responseURL.lastIndexOf("/")
              let id = res.request.responseURL.substr(index+1).split('.')[0]
              t['.key']=id
              let ticketIndex = this.myTickets.findIndex(x => x.eventId==id)
              t.myTicket=this.myTickets[ticketIndex]
              if (moment(t.end).isBefore(moment())){
                this.past.push(t)
              } else if(moment().isBefore(moment(t.start))){
                this.upcoming.push(t)
              } else {
                this.now.push(t)
              }
              // this.events.push(t)
            })
          }
          console.log('got it');
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      user: types.GET_USER
    }),
  },
  components: {
    EventCard,
    EventList
  }
}
</script>

<style lang="css" scoped>
</style>

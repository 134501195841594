<template lang="html">
  <v-layout  justify-center>
    <v-dialog v-model="show" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Create a Ticket</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-select
                :items="[{text:'Single Use', value:true},{text:'Multiple use', value:false}]"
                v-model="sUse"
                label="Single or multiple use?"
                @change="setUse"
                hint="Can this coupon be used just once or multiple times?"
              ></v-select>
              <v-flex xs12>

                <v-checkbox label="Auto generate Coupon" v-if="sUse==true" v-model="autoGen"></v-checkbox>
              </v-flex>
              <v-flex xs12>

              <v-text-field
                v-model="name"
                label="Discount Coupon Name:"
                id="id"
                v-if="autoGen!=true"
              ></v-text-field>
            </v-flex>
              <v-flex xs12 >
                <v-text-field label="Quantity" v-if="autoGen==true" type="number" hint="How many coupons do you want to generate?" v-model="q"></v-text-field>
              </v-flex>
              <v-select
                :items="[{text:'Absolute', value:true},{text:'Percentage', value:false}]"
                v-model="absVal"
                label="Absolute or % value of coupon"
              ></v-select>
              <v-flex xs12>
                <v-text-field
                  v-model="value"
                  label="Coupon value"
                  type="number"
                  :suffix="valueSuffix"
                  :error-messages="valueErrors"
                ></v-text-field>
              </v-flex>
              <v-flex xs12>
                  <v-menu
                  v-model="eDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                  >
                    <v-text-field
                      slot="activator"
                      label="Expiration date - Leave empty if no expiration date."
                      v-model="endDate"
                      prepend-icon="event"
                      readonly
                    ></v-text-field>
                      <v-date-picker v-model="endDate"   @input="eDateMenu = false">
                      </v-date-picker>
                  </v-menu>
              </v-flex>
            </v-layout>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" flat @click.native="changeShow">Close</v-btn>
          <v-btn color="primary" flat @click="submit" :disabled="sending">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import moment from 'moment'
import {
  required,
  maxLength,
  minLength
} from 'vuelidate/lib/validators'
import axios from 'axios'

export default {
  props:['show','eventId'],
  data: () => ({
    sUse:true,
    autoGen:true,
    eDateMenu:false,
    endDate:"",
    absVal:true,
    name:"",
    value:"",
    q:10,
    sending:false,
  }),
  methods: {
    changeShow(){
      this.$emit('changeShow')
    },
    setUse(){
      if (this.sUse==false){
        this.autoGen=false
        this.q=1
      }
    },
    generateCoupons(){
      for (var i = 0; i < this.q; i++) {
        let rand1=this.getRandomInt(1000)
        let rand2=this.getRandomInt(1000)
        this.postCoupon(rand1+this.value+rand2)
      }
    },
    getRandomInt(max) {
      return Math.floor(Math.random() * Math.floor(max));
    },
    postCoupon(name){
      let t={
        name:name,
        val:this.value,
        expDate:this.endDate,
        eventId:this.eventId,
        absVal:this.absVal,
        sUse:this.sUse,
      }
      axios.post('evCoupons.json?auth='+this.$store.state.auth.idToken, t)
      .then(()=>{
        console.log('succes');
        this.sending=false
      })
      .catch(err=>{
        console.log('error: ',err);
        this.sending=false
      })
    },
    submit() {
      this.sending=true
      // console.log('submit');
      if (this.validate()==true){
        if (this.autoGen==true && this.sUse==true){
          this.generateCoupons()
        } else {
          if (this.name!=""){
            this.postCoupon(this.name)
          }
        }
        this.$emit('changeShow')

      }
    },
    validate() {
      // console.log('DateL:  ', sSubmit)
      this.$v.$touch()
      // console.log(this.$v.$invalid);
      if (!this.$v.$invalid && !this.$v.$anyError) {
        // console.log('validate true');
        return true
      } else {
        this.sending=false
        // console.log('validate false');
        return false
      }
    },

  },
  validations: {
    value:{
      required
    },


  },
  computed:{
    valueSuffix(){
      if (this.absVal==true){
        return 'TW$'
      } else {
        return '%'
      }
    },
    valueErrors() {
      const errors = []
      if (!this.$v.value.$dirty) return errors
      !this.$v.value.required && errors.push('Add a coupon value')
      return errors
    },
    // startErrors() {
    //   const errors = []
    //   if (!this.$v.startDate.$dirty) return errors
    //   !this.$v.startDate.required && errors.push('Input a sales start date')
    //   return errors
    // },
    // endErrors() {
    //   const errors = []
    //   if (!this.$v.endDate.$dirty) return errors
    //   !this.$v.endDate.required && errors.push('Input a a sales end date')
    //   return errors
    // },
    // priceErrors() {
    //   const errors = []
    //   if (!this.$v.price.$dirty) return errors
    //   !this.$v.price.required && errors.push('Input a coupon price')
    //   return errors
    // },
    // qErrors() {
    //   const errors = []
    //   if (!this.$v.q.$dirty) return errors
    //   !this.$v.q.required && errors.push('Input a how many tickets are available')
    //   return errors
    // },
  },
  created() {
    //do something after creating vue instance
    if (this.ticket!=undefined){
      // console.log('creaate teh adticket', 'this.ticket:', this.ticketId, "this.ticket.name",this.ticket.name);
      // console.log('do this');
      this.edit=true

      this.name= this.ticket.name
      this.price= this.ticket.price
      this.q=this.ticket.q
      if (this.ticket.start!=""){
        this.startDate= moment.tz(this.ticket.start,"Asia/Taipei").format("YYYY-MM-DD")
      }
      if (this.ticket.end!=""){
        this.endDate= moment.tz(this.ticket.end,"Asia/Taipei").format("YYYY-MM-DD")
      }
    }


  }



}
</script>

<style lang="scss" scoped>
</style>

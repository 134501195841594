<template lang="html">
  <v-flex xs12>
    <v-layout  wrap>
      <v-flex xs2 sm1 class="">
        <v-layout  wrap justify-center>

          <span :class="{past:past}" class="month">{{month}}</span>
        </v-layout>
        <v-layout  wrap justify-center>
          <span :class="{past:past}" class="date">{{date}}</span>
        </v-layout>
      </v-flex>
      <v-flex xs8 sm9>
          <a :class="{past:past} " class="event-title" :href="'/event/'+myEvent['.key']">{{myEvent.title}}</a>
          <div :class="{past:past}">{{myEvent.location}}</div>
      </v-flex>
      <v-flex xs2>

        <v-btn color="primary" v-if="user.roleValue>21" :href="'/eventMgmt/'+myEvent['.key']">Manage</v-btn>
        <v-btn color="primary" :disable="disable" flat v-if="user.roleValue>50" @click="duplicate()">Duplicate</v-btn>
      </v-flex>

    </v-layout>
  </v-flex>


</template>

<script>
import moment from 'moment'
import axios from 'axios'

export default {
  props: ['myEvent','past', 'user'],
  data: () => ({
    disable:false,
  }),
  computed:{
    month(){
      return moment.tz(this.myEvent.start,"Asia/Taipei").format("MMM")
    },
    date(){
      return moment.tz(this.myEvent.start,"Asia/Taipei").format("DD")
    },

  },
  methods: {
    duplicate() {
      this.disable=true
      console.log(this.myEvent, this.user);
      let newEvent={
        description:this.myEvent.description,
        end:moment().add(1,'M').format(),
        img:this.myEvent.img,
        location:this.myEvent.location,
        sdesc:this.myEvent.sdesc,
        start:moment().add(1,'M').format(),
        title:this.myEvent.title
      }
      console.log('New event: ', newEvent);
      axios.post('/events.json?auth='+this.$store.state.auth.idToken, newEvent)
      .then(res=>{
        console.log('success', res.data);
        this.$router.push('/addEvent?id='+res.data.name)
      })
    }
  }

}
</script>

<style lang="css" scoped>

.month{
  color:red;
  font-size: 1.2em
}
.date{
  font-size: 1.3em;
  font-weight: bolder;
}
.past{
  color:grey!important;
}
.event-title{
  color:rgba(0, 0, 0, 0.6);
  text-decoration: none;
  font-size:1.3em;
}
.event-title:hover{
  font-weight: 450;
  text-decoration: underline;
}
</style>

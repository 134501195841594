<template lang="html">
<div class="">

  <navbar></navbar>
  <Loading v-if="loading"></Loading>
  <v-container v-else grid-list-md>
    <v-layout  wrap align-center justify-center class="boxed">
      <v-flex xs12 py-3>
        <h1 class="text-xs-center"> Your Ticket for: {{myEvent.title}}</h1>
      </v-flex>
      <v-flex xs8 sm6 class="text-xs-center py-4">
        <v-layout  wrap justify-space-around>

          <qrCode :text="qrLink"></qrCode>
        </v-layout>
      </v-flex>
      <v-flex xs12 sm6>
        <v-layout  wrap>
          <v-flex xs12 v-if="ticket.used==true">
            <span class="alert">Ticket has been used</span>
          </v-flex>
          <v-flex xs12 class="subheading">
            Date: <span class="details">{{myEvent.start | time}}</span>
          </v-flex>
          <v-flex xs12 class="subheading">
            Location: <span class="details">{{myEvent.location}}</span>
          </v-flex>

          <v-flex xs12 class="subheading">
            Guest: <span class="details"><span v-if="user.name!=undefined">{{user.name}}</span><span v-else>{{user.userName}}</span></span>
          </v-flex>

          <v-flex xs12>
            Description: <span>{{myEvent.sdesc}}</span>
          </v-flex>
          <v-flex xs12>
            Please present this QRCode at the event.
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</div>
</template>

<script>

import moment from 'moment'
import axios from 'axios'
import config from '../../../config'
import firebase from '../../../FirebaseApp'
import {
  mapGetters,
} from 'vuex'
import * as types from '../../../store/types'
import Loading from '../../shared/Loading'
import VueQRCodeComponent from 'vue-qrcode-component'


export default {
  data: () => ({
    myEvent:{},
    loading:true
  }),

  firebase() {
    return {
      ticket:{
        source:firebase.database().ref('tickets/'+this.$route.params.id),
        asObject:true,
        readyCallback:function(){
          if (this.ticket.owner==this.user.id){
            axios.get('events/'+this.ticket.eventId+'.json')
            .then(res=>{
              this.myEvent=res.data
              this.loading=false
            })
          } else {
            console.log("This is not your ticket!")
            this.$router.push('/events')
          }
        }
      },


    }
  },
  computed: {
      ...mapGetters({
        user: types.GET_USER
      }),
      baseUrl(){
        return config.CARDURL
      },
      qrLink(){
        let text={
          ticket:this.$route.params.id,
          name:this.user.userName
        }
        return JSON.stringify(text)
      }
  },
  filters:{
    time(t){
      return moment.tz(t,'Asia/Taipei').format("ddd, LLL")
    }
  },

  created() {
    //do something after creating vue instance
  },
  components: {
      Loading,
      qrCode:VueQRCodeComponent
  }
}
</script>

<style lang="css" scoped>
  .boxed{
    border-color: rgba(0,0,0,0.5);
    border-width: thin;
    border-style: solid;
  }
  .bleft{
    border-left: rgba(0,0,0,0.5) thin solid;
  }
  .bright{
    border-right: rgba(0,0,0,0.5) thin solid;
  }
  .bbottom{
    border-bottom: rgba(0,0,0,0.5) thin solid;
  }
  .btop{
    border-top: rgba(0,0,0,0.5) thin solid;
  }
  .grey-bg{
    background-color: rgba(0,0,0,.2)
  }
  .details{
    font-weight: 450
  }
  .alert{
    color:red;
    font-weight: 450;
  }
</style>

<template lang="html">
  <div class="pa-3">
    <v-card width="" class="" hover :href="'/event/'+myEvent['.key']">
      <v-img
         :src="baseUrl+myEvent.img[0].id"
         aspect-ratio="2.75"
       ></v-img>
       <v-layout  wrap class="pa-2">
        <v-flex xs2 class="text-xs-center pt-2">
          <v-layout  wrap justify-center>

            <span class="month">{{month}}</span>
          </v-layout>
          <v-layout  wrap justify-center>
            <span class="date">{{date}}</span>
          </v-layout>
        </v-flex>
        <v-flex xs9>
          <v-card-title class="pb-1" primary-title>
            {{myEvent.title}}
          </v-card-title>
          <v-card-text class="pt-1">
            <div>{{longStart}}</div>
            <div>{{myEvent.location}}</div>
            <div class="">
              {{price}}
            </div>
            <!-- {{myEvent.description}} -->
          </v-card-text>
        </v-flex>

      </v-layout>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" v-if="user.roleValue>21"  :href="'/eventMgmt/'+myEvent['.key']">Manage</v-btn>
        <!-- <v-btn color="primary" :disable="disable" flat :href="'/'" v-if="user.roleValue>50" @click="duplicate()">Duplicate</v-btn> -->
        <v-btn color="#ff4000" dark  :href="'/ticketDetails/'+ticket['.key']" v-if="showTicket==true">My Ticket</v-btn>
        <v-btn color="primary"  :href="'/event/'+myEvent['.key']">Details</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import config from '../../../config'
import moment from 'moment'

export default {
  props: ['myEvent', 'user', 'ticket'],
  data: () => ({
    disable:false
  }),
  methods: {
    // duplicate() {
    //   this.disable=true
    //   console.log(this.myEvent, this.user);
    //   let newEvent={
    //     description:this.myEvent.description,
    //     end:moment().add(1,'M').format(),
    //     img:this.myEvent.img,
    //     location:this.myEvent.location,
    //     sDesc:this.myEvent.sDesc,
    //     start:moment().add(1,'M').format(),
    //     title:this.myEvent.title
    //   }
    //   console.log('New event: ', newEvent);
    //   axios.post('/events.json?auth='+this.$store.state.auth.idToken, newEvent)
    //   .then(res=>{
    //     console.log('success', res.data);
    //     this.$router.push('/addEvent?id='+res.data.name)
    //   })
    // }
  },
  computed:{
    baseUrl(){
      // console.log(config);
      return config.CARDURL
    },
    month(){
      return moment.tz(this.myEvent.start,"Asia/Taipei").format("MMM")
    },
    date(){
      return moment.tz(this.myEvent.start,"Asia/Taipei").format("DD")
    },
    longStart(){
      return moment.tz(this.myEvent.start,'Asia/Taipei').format("ddd, "+"LLL")
    },
    price(){
      if (this.myEvent.hasOwnProperty('tickets')){
        let t=null
        for (var i in this.myEvent.tickets) {
          if (this.myEvent.tickets.hasOwnProperty(i)) {
            if (t==null){
              t=this.myEvent.tickets[i].price
            } else if (this.myEvent.tickets[i].price<t){
              t=this.myEvent.tickets[i].price
            }
          }
        }
        if (t==0) {
          return 'free'
        } else {
          return 'tickets from: ' +t + ' TWD'
        }
      } else {
        return "No Tickets available yet"
      }
    },
    showTicket(){
      if (this.ticket!=undefined){
        return true
      } else {
        return false
      }
    },
    // baseUrl(){
    //   return config.PHOTOURL
    // }
  },


}
</script>

<style lang="css" scoped>
.month{
  color:red;
  font-size: 1.2em
}
.date{
  font-size: 1.3em;
  font-weight: bolder;
}
</style>

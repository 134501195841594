<template>
  <div>
    <navbar></navbar>

    <h1 class="text-xs-center ma-3">Ticket check for : {{myEvent.title}}</h1>
    <qrcode-stream :camera="camera" @decode="onDecode" @init="onInit">
      <div v-show="cameraForzen" class="validation-layer">
        <div class="validation-notice" v-if="validating">
          Long validation in progress...
        </div>

        <div class="validation-result" v-if="!validating">
          <div v-if="isValid" class="valid">
            Welcome {{result.name}}
          </div>

          <div v-else class="invalid">
            Some Error:
            <br>
            {{error}}
          </div>
        </div>
      </div>
    </qrcode-stream>
    <v-layout  wrap justify-center>
      <v-flex xs11 sm9 md6>
        <v-layout  wrap>
          <v-flex xs12>
            <h2>Previous Party Animal</h2>
          </v-flex>
          <!-- <p class="decode-result">Last result: <b>{{ result }}</b></p> -->
          <v-flex xs12>
            Name: <span class="result" v-if="pastTicket.time!=undefined">{{result.name}}</span>
          </v-flex>
          <v-flex xs12>
            Event: <span v-if="pastTicket.eventId!=undefined" class="result"> {{myEvent.title}}</span>
          </v-flex>

          <v-flex xs12>
            Ticket Type: <span  class="result" v-if="myEvent.tickets!=undefined && pastTicket.type!=undefined">{{myEvent.tickets[pastTicket.type].name}}</span>
          </v-flex>
          <v-flex xs12>
            Time since validation: <span  class="result" v-if="pastTicket.ts!=undefined">{{pastTicket.ts | diff}}</span>
          </v-flex>
          <v-flex xs12>
            Date bought: <span  class="result" v-if="pastTicket.time!=undefined"> {{pastTicket.time | time}}</span>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout  wrap justify-center>
      <v-flex xs12 md6>
        <v-select :items="allEvents" v-model="newEvent" item-text="title" item-value="val" @change="go"></v-select>
        <!-- <v-btn color="primary" @click="go" v-if="newEvent!=''">GO</v-btn> -->

      </v-flex>

    </v-layout>

  </div>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
import config from '../../../config'
import firebase from '../../../FirebaseApp'
import {
  mapGetters,
} from 'vuex'
import * as types from '../../../store/types'
import {QrcodeStream} from 'vue-qrcode-reader'

export default {
  data () {
    return {
      isValid: false,
      validating: false,
      camera: {},
      result: {},
      pastTicket:{},
      error:"",
      loading: false,
      firstLoad: true,
      newEvent:"",
    }
  },
  firebase() {
    return {
      allEvents:{
        source: firebase.database().ref('events').orderByChild('end').startAt(moment().subtract(2,"days").toISOString()),
        readyCallback:function(){
          for (var i = 0; i < this.allEvents.length; i++) {
            this.allEvents[i].val=this.allEvents[i]['.key']
          }
          console.log('got it');
        }
      },
      myEvent: {
        source:firebase.database().ref('events/'+this.$route.params.eventId),
        asObject:true,
        readyCallback:function(){
          console.log('got it');
        }
      },
      tickets:{
        source:firebase.database().ref('tickets').orderByChild('eventId').equalTo(this.$route.params.eventId),
        asObject:true,
        readyCallback:function(){
        }
      }
    }
  },

  computed: {
    ...mapGetters({
      user: types.GET_USER
    }),
    baseUrl() {
      return config.CARDURL
    },
    cameraForzen() {
      return this.camera === false || (this.loading && !this.firstLoad)
    }
  },
  methods: {
    go(){
      this.$router.push('/checking/'+this.newEvent)
      this.$router.go()
    },
    async onDecode (content) {
       this.result = content

       this.stopCamera()

       this.validating = true
       this.isValid = await this.validate(content)
       this.validating = false
       window.setTimeout(() => {
         this.startCamera()
       }, 3000)
     },

     stopCamera () {
       this.camera = false
     },

     startCamera () {
       // use default settings
       this.camera = {}
     },

     validate (content) {
       console.log(content, 'the content of the qr');
       return new Promise(resolve => {
         window.setTimeout(() => { // pretend it's taking really long
           if (this.checkTicket()==true) {
             axios.patch('tickets/'+this.result.ticket+'.json?auth='+this.$store.state.auth.idToken,{used:true, ts:moment()})
             .then(()=>{

               resolve(true)
             })
           } else {
             console.log('resolve false');
             resolve(false)
           }
         }, 100)
       })
     },

     async onInit (promise) {
       this.loading = true

       try {
         await promise
       } catch (error) {
         console.error(error)
       } finally {
         this.firstLoad = false
         this.loading = false
       }
     },
    checkTicket() {
      // console.log(this.result.ticket);
      this.pastTicket={}
      try {
        this.result = JSON.parse(this.result)
      }
      catch(error) {
        this.error="Please use an actual ticket QR code"
        console.log('Wrong type');
        console.error(error);
        return false
        // expected output: ReferenceError: nonExistentFunction is not defined
        // Note - error messages will vary depending on browser
      }

      if (!this.result instanceof Object | !this.result.hasOwnProperty('name') || !this.result.hasOwnProperty('ticket')){
        this.error="Please use an actual ticket QR code"
        console.log('Wrong type');
        return false
      }
      if (!this.tickets.hasOwnProperty(this.result.ticket)) {
        this.error='Ur at the wrong event bro'
        return false
      } else if (this.tickets[this.result.ticket].used) {
        this.pastTicket=this.tickets[this.result.ticket]
        this.error='Ticket has already been used'
        return false
      } else {
        this.pastTicket=this.tickets[this.result.ticket]
        console.log("it's good");
        return true
      }
    },
  },

    created() {
      //do something after creating vue instance
    },
    components: {
      QrcodeStream,
    },
    filters:{
      time(t){
        return moment(t).format("LLL")
      },
      diff(t){
        return moment.duration(moment(t).diff(moment())).humanize()
      }
    }
  }
</script>

<style lang="css" scoped>

.validation-layer {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, .8);
  text-align: center;
  padding: 10px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}

.validation-notice,
.validation-result {
  font-weight: bold;
  font-size: 1.4rem;
}

.valid {
  color: green;
}
.invalid {
  color: red;
}
.result{
  font-weight:500;
}
</style>

<template lang="html">
  <div class="">
    <navbar></navbar>
    <v-layout  wrap justify-space-around class="mt-2">
      <v-flex xs12 sm11 md10>
        <v-layout  wrap>
          <v-toolbar>
            <v-toolbar-title>Upcoming Events</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="#FF4000" v-if="user.roleValue>79" :href="'/addEvent'" dark>+ Add Event </v-btn>
          </v-toolbar>
          <v-flex xs12 sm6 md4 v-for="myEvent in upcoming" :key="myEvent['.key']">
            <EventCard :myEvent="myEvent" :user="user"></EventCard>
          </v-flex>
        </v-layout>
        <v-layout  wrap>
          <v-toolbar>
            <v-toolbar-title>Happening Now</v-toolbar-title>
          </v-toolbar>
          <v-flex xs12 class="py-2">

            <v-layout  wrap v-for="myEvent in now" :key="myEvent['.key']" class="py-1">
              <EventList :myEvent="myEvent" :user="user" :past="false" ></EventList>
            </v-layout>
          </v-flex>

      </v-layout>
      <v-layout  wrap>
        <v-toolbar>
          <v-toolbar-title>Past</v-toolbar-title>
        </v-toolbar>
        <v-flex xs12 class="py-2">

          <v-layout  wrap v-for="myEvent in past" :key="myEvent['.key']" class="py-1">

              <EventList :myEvent="myEvent" :user="user" :past="true" ></EventList>
          </v-layout>
        </v-flex>
      </v-layout>

    </v-flex>
  </v-layout>
  </div>
</template>

<script>
import firebase from '../../../FirebaseApp'
import moment from 'moment'
import EventCard from './EventCard.vue'
import EventList from './EventList.vue'
import {
  mapGetters
} from 'vuex'

import * as types from '../../../store/types'



export default {
  data: () => ({
    upcoming:[],
    past:[],
    now:[],
  }),
  firebase() {
    return {
      events: {
        source:firebase.database().ref('events'),
        readyCallback:function(){
          for (var i = 0; i < this.events.length; i++) {
            console.log(this.events[i].title,'end',moment(this.events[i].end).format('LLLL'), 'START:',moment().format("LLLL"));
            if (moment(this.events[i].end).isBefore(moment())){
              this.past.push(this.events[i])

            } else if (moment().isBefore(moment(this.events[i].start))){
              this.upcoming.push(this.events[i])
            } else {
              this.now.push(this.events[i])
            }
          }
          let t=[]
          for (var i = 0; i < this.upcoming.length; i++) {
            t.push(this.upcoming[i]['.key'])
          }
          dataLayer.push({
            currentEvents:t
          })
          this.upcoming.sort((a, b) => (a.start<b.start) ? -1 : 1)
        }
      }
    }
  },
  computed:{
    ...mapGetters({

      user: types.GET_USER
    }),
  },
  components: {
    EventCard,
    EventList
  }
}
</script>

<style lang="scss" scoped>
.linkedList :hover{
  cursor: pointer;
}
</style>

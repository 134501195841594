<template lang="html">
  <div class="">
    <navbar></navbar>
    <v-container grid-list-xs,sm,md,lg,xl>

      <v-layout  wrap>
        <v-flex xs12>
          <h1>{{myEvent.title}}</h1>
        </v-flex>
        <v-divider></v-divider>
        <v-flex xs12 align-center>
          <v-layout  wrap align-center>

            <span>
              {{myEvent.start | dateTime}}
            </span>
            <v-icon>arrow_right</v-icon>
            <span>
              {{myEvent.end | dateTime}}
            </span>
          </v-layout>
        </v-flex>
        <v-flex xs12>
          <h2>Description</h2>
          <v-layout  wrap class=" pa-2">
            <v-flex xs12 class="pa-0" v-for="(el,index) in textbox" :key="index">
              {{el}}
              <br>
            </v-flex>
          </v-layout>

        </v-flex>
      </v-layout>
      <v-layout  wrap align-center>
        <v-flex xs3>
          <h2>Tickets</h2>
        </v-flex>
        <v-flex xs9 class="text-xs-right">
          <v-btn color="primary" :disable="disable" flat v-if="user.roleValue>50" @click="duplicate()">Duplicate</v-btn>
          <v-btn color="primary" :href="'/event/'+this.$route.params.id">See event</v-btn>
          <v-btn color="secondary" :href="'/attendees/'+this.$route.params.id">Guest List</v-btn>
          <v-btn color="#ff4000" dark :href="'/addEvent?id='+this.$route.params.id">Edit event</v-btn>
          <v-btn color="primary" @click="show=true">Add Tickets</v-btn>
        </v-flex>

        <v-flex xs12>

          <v-layout  wrap class="header py-2">
            <v-flex xs3>
              Ticket Type
            </v-flex>
            <v-flex xs2>
              Price
            </v-flex>
            <v-flex xs1>
              Sold
            </v-flex>
            <v-flex xs2>
              status
            </v-flex>
            <v-flex xs2>
              End Sales
            </v-flex>
            <v-flex xs2>
              Edit ticket
            </v-flex>
          </v-layout>
        </v-flex>

        <v-flex xs12 v-if="myEvent.tickets!=undefined"  v-for="(ticket, index) in myEvent.tickets" :key="index">
          <TicketType class="py-1" :eventId="$route.params.id" :soldTickets="soldTickets" :index="index" :ticket="ticket"></TicketType>
        </v-flex>

        <AddTicket :show="show" @changeShow="show=!show" :id="$route.params.id"></AddTicket>
      </v-layout>
      <v-layout  wrap align-center>
        <v-flex xs8>
          <h2>Coupons</h2>
        </v-flex>
        <v-flex xs4 class="text-xs-right">
          <v-btn color="primary" @click="showCoupon=true">Add Coupons</v-btn>
        </v-flex>

        <v-flex xs12>

          <v-layout  wrap class="header py-2">
            <v-flex xs3>
              Coupon Code
            </v-flex>
            <v-flex xs2>
              Value
            </v-flex>
            <v-flex xs2>
              Type
            </v-flex>
            <v-flex xs2>
              Status
            </v-flex>
            <v-flex xs2>
              Actions
            </v-flex>
          </v-layout>
        </v-flex>

        <v-flex xs12 v-if="coupons!=undefined"  v-for="(coupon, index) in coupons" :key="index">
          <CouponType class="py-1" :coupon="coupon" @editCSent="changeSent(coupon, $event)"></CouponType>
        </v-flex>

      </v-layout>
      <AddCoupon :show="showCoupon" @changeShow="showCoupon=!showCoupon" :eventId="$route.params.id"></AddCoupon>

    </v-container>
  </div>
</template>

<script>
import firebase from '../../../FirebaseApp'
import moment from 'moment'
import TicketType from './TicketType.vue'
import AddTicket from './AddTicket.vue'
import AddCoupon from './AddCoupon.vue'
import CouponType from './CouponType.vue'
import {
  mapGetters,
} from 'vuex'
import * as types from '../../../store/types'
import axios from 'axios'

export default {
  data: () => ({
    show:false,
    disable:false,
    showCoupon:false,
  }),

  firebase() {
    return {
      myEvent: {
        source:firebase.database().ref('events/'+this.$route.params.id),
        asObject:true,
        readyCallback:function(){
          console.log('got it');
        }
      },
      coupons: {
        source:firebase.database().ref('evCoupons').orderByChild('eventId').equalTo(this.$route.params.id),
        readyCallback:function(){
          console.log('got it');
          for (var i = 0; i < this.coupons.length; i++) {
            if (this.coupons[i].sent==undefined){
              this.coupons[i].sent=false
            }
          }
        }
      },
      soldTickets:{
        source:firebase.database().ref('tickets').orderByChild('eventId').equalTo(this.$route.params.id),
        asObject:true,
        readyCallback:function(){
          console.log('got it');
        }
      }
    }
  },
  filters:{
    dateTime(time){
      return moment.tz(time,"Asia/Taipei").format("LLLL")
    }
  },
  computed:{
    ...mapGetters({
      user: types.GET_USER
    }),

    textbox(){
      if (this.myEvent.description!=undefined){
        return this.myEvent.description.split('\n')
      } else {
        return ""
      }
    }
  },
  methods: {
    changeSent(coupon, payload) {
      console.log(payload, 'Change event');
      coupon.sent=payload

    },
    duplicate() {
      this.disable=true
      console.log(this.myEvent, this.user);
      let newEvent={
        description:this.myEvent.description,
        end:moment().add(1,'M').format(),
        img:this.myEvent.img,
        location:this.myEvent.location,
        sdesc:this.myEvent.sdesc,
        start:moment().add(1,'M').format(),
        title:this.myEvent.title
      }
      console.log('New event: ', newEvent);
      axios.post('/events.json?auth='+this.$store.state.auth.idToken, newEvent)
      .then(res=>{
        console.log('success', res.data);
        this.$router.push('/addEvent?id='+res.data.name)
      })
    },
  },
  components:{
    TicketType,
    AddTicket,
    AddCoupon,
    CouponType,
  }


}

</script>

<style lang="scss" scoped>
.header{
  background-color: lightgrey;
  font-weight: bold;
  font-size: 1.1em;

}
</style>

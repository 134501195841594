<template lang="html">
    <v-layout  justify-center>
      <v-dialog v-model="show" persistent max-width="500px">
        <v-card>
          <v-card-title class="headline">Select your ticket type</v-card-title>
          <v-card-text>
            <v-radio-group v-model="type">
                <v-radio v-for="(elem,id) in avTickets" :disabled="elem.unAv" :key="id" :label="elem.name + ' ('+elem.price+'TW$)'" :value="elem">
                  <v-layout slot="label"  wrap>
                    <v-flex xs9>
                      <div class="">

                        <span>{{elem.name}} - <span class="price">{{elem.price}} TW$</span></span>
                      </div>
                      <div class="">

                        <span color="secondary" v-if="elem.unAv">{{elem.expl}}</span>
                        <span color="secondary" v-else>{{elem.av}} tickets available</span>
                      </div>
                    </v-flex>
                    <v-flex xs3>
                      <v-select
                        v-if="elem.av>1"
                        :items="numberOfTickets(elem)"
                        v-model="elem.number"
                        label="How many tickets"
                      ></v-select>
                    </v-flex>

                  </v-layout>

                </v-radio>
            </v-radio-group>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" flat @click="close">Cancel</v-btn>
            <v-btn color="primary" :disabled="ticketSelected" flat @click="confirm">Buy</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
</template>

<script>

import moment from 'moment'
export default {
  props:['show','tickets','sold'],
  data: () => ({
    type:""
  }),
  methods: {
    close() {
      this.$emit('close')
    },
    confirm(){
      this.$emit('confirm',this.type)
    },
    numberOfTickets(type){
      let arr=[]

      for (var i = 0; i < type.av; i++) {
        arr.push(i+1)
      }
      return arr
    }
  },
  computed:{
    ticketSelected(){
      if (this.type!=""){
        return false
      } else {
        return true
      }
    },
    avTickets(){
      console.log('doAvTickets');
      for (var el in this.tickets) {
        if (this.tickets.hasOwnProperty(el)) {
          let temp=this.tickets[el]
          this.tickets[el]['.key']=el
          this.tickets[el].unAv=false
          this.tickets[el].number=1
          if(moment(temp.end).isBefore(moment())){
            console.log('it has passed, skip to next iteration');
            this.tickets[el].unAv=true
            this.tickets[el].expl="Sale has ended"
          } else if (moment().isBefore(moment(temp.start))){
            console.log('Sale has not yet started, skip to  next iteration');
            this.tickets[el].unAv=true
            this.tickets[el].expl="Sale starts on "+moment(temp.start).format('LLL')+" ("+this.tickets[el].q+" Tickets available)"

          }
          // CHECK if sold outl
          let nSold=0
          console.log(this.sold);
          if (this.sold.hasOwnProperty(['.key'])&& this.sold.hasOwnProperty(['.value'])!==null){

            for (var t in this.sold) {
              if (this.sold.hasOwnProperty(t) && this.sold[t]!=null) {
                console.log(this.sold[t]);
                if (this.sold[t].type==el){
                  nSold++
                }
              }
            }
          }
          this.tickets[el].av=this.tickets[el].q-nSold
          if (nSold>=this.tickets[el].q){
            this.tickets[el].unAv=true
            this.tickets[el].av=0
            this.tickets[el].expl="Sold out!"
          }
          console.log(nSold,'TicketsSold');
          this.tickets[el].nSold=nSold
        }
      }
      return this.tickets
    }
  }
}
</script>

<style lang="css" scoped>
</style>

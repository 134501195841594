<template lang="html">
  <div class="">
    <v-layout  wrap>
      <v-flex xs3>
        {{ticket.name}}
      </v-flex>
      <v-flex xs2>
        {{ticket.price}} TW$
      </v-flex>
      <v-flex xs1>
        {{sold}}/{{ticket.q}}
      </v-flex>
      <v-flex xs2>
        {{status}}
      </v-flex>
      <v-flex xs2>
        {{ticket.end | date}}
      </v-flex>
      <v-flex xs2>
        <v-btn color="primary" @click="show=!show">EDIT</v-btn>
      </v-flex>
    </v-layout>
    <AddTicket :show="show" :ticket="ticket" :ticketId="index" @changeShow="show=!show" :id="eventId"></AddTicket>

  </div>
</template>

<script>
import moment from 'moment'
import AddTicket from './AddTicket.vue'
export default {
  props:['ticket','soldTickets','index','eventId'],
  data: () => ({
    show:false,
  }),
  computed:{
    sold(){
      let t=0

      // console.log('do sold,' ,this.soldTickets);
      // console.log('if check',this.soldTickets.hasOwnProperty('.key'),  'should be true:',this.soldTickets['.value']!==null)
      if (this.soldTickets.hasOwnProperty('.key') && this.soldTickets['.value']!==null){
        // console.log('inside if');
        for (var item in this.soldTickets) {
          // console.log('test');
          if (this.soldTickets.hasOwnProperty(item)) {
            // console.log('compare', this.soldTickets[item].type, this.index);
            if (this.soldTickets[item].type==this.index){
              t++
            }
          }
        }
      }
      return t
    },
    status(){
      if (moment(this.ticket.end).isBefore(moment())){
        return 'Sale has ended'
      } else if (moment().isBefore(moment(this.ticket.start))) {
        let s=moment(this.ticket.start).format('LLL')
        return 'Sale will Start on '+s
      } else if (this.sold<this.ticket.q){
        return 'On Sale'
      } else {
        return 'Sold Out'
      }
    }
  },
  filters:{
    date(t){
      return moment(t).format('LLL')
    }
  },
  components: {
    AddTicket
  }

}
</script>

<style lang="css" scoped>
</style>

<template>
<div>
  <navbar></navbar>
  <v-container grid-list-xs,sm,md,lg,xl>

  <v-card>
   <v-card-title>
     Guest list for {{myEvent.title}}
     <v-spacer></v-spacer>
     <v-text-field
       v-model="search"
       append-icon="search"
       label="Search"
       single-line
       hide-details
     ></v-text-field>
   </v-card-title>
   <v-data-table
     :rows-per-page-items='[25,10,5,{"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}]'
     :headers="headers"
     :items="tickets"
     :search="search"
   >
     <template slot="items" slot-scope="props">
       <td><span v-if="props.item.used==true">Used: {{props.item.ts | diff }} ago</span><span v-else>Not yet used</span> </td>
       <td class="" v-if="users.hasOwnProperty(props.item.owner)">
         <a :href="'/profile?id='+props.item.owner">
           <span v-if="users.hasOwnProperty(props.item.owner) && users[props.item.owner].hasOwnProperty('name')">{{users[props.item.owner].name }}</span>
           <span v-else>{{users[props.item.owner].userName }}</span>
        </a>
       </td>
       <td>
         <a :href="'mailto:'+users[props.item.owner].email+'?Subject=Hello%20again'">{{users[props.item.owner].email}}</a>
       </td>
       <td class="">{{ props.item.price }}TWD</td>
       <td class="" v-if="myEvent!=null && myEvent.tickets.hasOwnProperty(props.item.type)">{{myEvent.tickets[props.item.type].name}} </td>
       <td><span v-if="users!=undefined && users.hasOwnProperty(props.item.owner)">{{users[props.item.owner].school}}</span></td>
       <td>
         <div class="" v-if="users.hasOwnProperty(props.item.owner) && users[props.item.owner].hasOwnProperty('bookingRequestOut') && users[props.item.owner].bookingRequestOut!=''">
           yes
         </div>
         <div class="" v-else>
           NO
         </div>
       </td>
       <td><a :href="'https://console.firebase.google.com/u/0/project/my-room-abroad/database/my-room-abroad/data/tickets/'+props.item['.key']">{{props.item['.key']}}</a></td>

     </template>
     <v-alert slot="no-results" :value="true" color="error" icon="warning">
       Your search for "{{ search }}" found no results.
     </v-alert>
   </v-data-table>
 </v-card>
</v-container>
</div>
</template>

<script>
import firebase from '../../../FirebaseApp'
import moment from 'moment'
import axios from 'axios'
import {
  mapGetters
} from 'vuex'
import * as types from '../../../store/types'

export default {
  name: 'Attendees',
  data: () => ({
    headers: [
      { text: 'Used?', value: 'used' },
      { text: 'Guest Name', value: 'username'},
      { text: 'Guest Email', value: 'email'},
      { text: 'Price Paid', value: 'price' },
      { text: 'Ticekts type', value:'typeName'},
      { text: 'School', value:'school'},
      { text: 'Room @ MRA?', value:'cust'},
      { text: 'db', value:'.key'}
    ],
    search: null,
    searched: [],
  }),
  firebase() {
    return {
      myEvent: {
        source:firebase.database().ref('events/'+this.$route.params.eventId),
        asObject:true,
        readyCallback:function(){
          console.log('got it');
          this.matchTicketsEvent()
        }
      },
      tickets:{
        source:firebase.database().ref('tickets').orderByChild('eventId').equalTo(this.$route.params.eventId),
        readyCallback:function(){
          this.matchTicketsUsers()
          this.matchTicketsEvent()
        }
      },
      users: {
        source:firebase.database().ref('users').orderByChild('lastLogin').limitToLast(1000),
        asObject:true,
        readyCallback:function(){
          this.matchTicketsUsers()
        }
      },
    }
  },
  methods: {
    matchTicketsUsers(){
      console.log(this.tickets && this.users);
      if (this.users!=null && this.tickets!=undefined && this.tickets.length>0){
        for (var i = 0; i < this.tickets.length; i++) {
          if (this.users.hasOwnProperty(this.tickets[i].owner)) {
            this.tickets[i].username=this.users[this.tickets[i].owner].userName
            this.tickets[i].school=this.users[this.tickets[i].owner].school
            if (this.users[this.tickets[i].owner].hasOwnProperty('bookingRequestOut') && this.users[this.tickets[i].owner].bookingRequestOut!='' ) {
              this.tickets[i].cust=true
            } else {
              this.tickets[i].cust=false
            }
          }
          if (this.tickets[i].used==undefined){
            this.tickets[i].used=false
          }
        }
      }
    },
    matchTicketsEvent(){
      if (this.myEvent!=null && this.tickets!=undefined && this.tickets.length>0){
        for (var i = 0; i < this.tickets.length; i++) {
          this.tickets[i].typeName=this.myEvent.tickets[this.tickets[i].type].name
        }
      }
    }
  },
  filters: {
    dateBought: function(t) {
      if (t!=undefined){
        return moment(t).format('ll')
      } else {
        return "Nothing to show"
      }

    },
    diff(t){
      return moment.duration(moment(t).diff(moment())).humanize()
    }
  },
  computed: {
    ...mapGetters({
      user: types.GET_USER
    }),
  },
  created() {
  }
}
</script>

<style lang="scss" scoped>
.md-field {
    max-width: 300px;
}
</style>

<template lang="html">
  <v-layout  justify-center>
    <v-dialog v-model="show" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Create a Ticket</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field label="Ticket Name*" :error-messages="nameErrors" v-model="name" hint="Something like regular/ early bird/..." required></v-text-field>
              </v-flex>
              <v-flex xs6>
                  <v-menu
                  v-model="sDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                  >
                    <v-text-field
                      slot="activator"
                      label="Start Sale*"
                      v-model="startDate"
                      prepend-icon="event"
                      :error-messages="startErrors"
                      readonly
                    ></v-text-field>
                    <v-date-picker v-model="startDate"  @input="sDateMenu = false">
                    </v-date-picker>
                  </v-menu>
              </v-flex>
              <v-flex xs6>
                  <v-menu
                  v-model="eDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                  >
                    <v-text-field
                      slot="activator"
                      label="End Sale*"
                      v-model="endDate"
                      prepend-icon="event"
                      readonly
                      :error-messages="endErrors"
                    ></v-text-field>
                      <v-date-picker v-model="endDate"   @input="eDateMenu = false">
                      </v-date-picker>
                  </v-menu>
              </v-flex>
              <v-flex xs12 sm6 >
                <v-text-field label="Price*" :error-messages="priceErrors" type="number" suffix="NT$" hint="Sale Price" v-model="price"></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 >
                <v-text-field label="Quantity*" :error-messages="qErrors" type="number" suffix="People" hint="How many people can buy this ticket?" v-model="q"></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" flat @click.native="changeShow">Close</v-btn>
          <v-btn color="primary" flat @click="submit" :disabled="sending">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import moment from 'moment'
import {
  required,
  maxLength,
  minLength
} from 'vuelidate/lib/validators'
import axios from 'axios'

export default {
  props:['show','id','ticket','ticketId'],
  data: () => ({
    sDateMenu:false,
    edit:false,
    startDate:"",
    eDateMenu:false,
    endDate:"",
    name:"",
    price:"",
    q:"",
    sending:false,
  }),
  methods: {
    changeShow(){
      this.$emit('changeShow')
    },
    submit() {
      this.sending=true
      // console.log('submit');
      if (this.validate()==true){
        let t={
          start:this.startDate,
          end:this.endDate,
          name:this.name,
          price:this.price,
          q:this.q,
        }
        if (this.edit==false){
          console.log('dopost');
          axios.post('events/'+this.id+'/tickets.json?auth='+this.$store.state.auth.idToken, t)
          .then(()=>{
            console.log('succes');
            this.sending=false
          })
          .catch(err=>{
            console.log('error: ',err);
          })
        }
        else {
          console.log('do patch');
          axios.patch('events/'+this.id+'/tickets/'+this.ticketId+'.json?auth='+this.$store.state.auth.idToken, t)
          .then(()=>{
            this.sending=false
          })
        }
        this.$emit('changeShow')

      }
    },
    validate() {
      // console.log('DateL:  ', sSubmit)
      this.$v.$touch()
      // console.log(this.$v.$invalid);
      if (!this.$v.$invalid && !this.$v.$anyError) {
        // console.log('validate true');
        return true
      } else {
        this.sending=false
        // console.log('validate false');
        return false
      }
    },

  },
  validations: {
    name: {
      required,
    },
    price:{
      required,
    },
    startDate:{
      required
    },
    endDate:{
      required
    },
    q:{
      required
    }

  },
  computed:{
    nameErrors() {
      const errors = []
      if (!this.$v.name.$dirty) return errors
      !this.$v.name.required && errors.push('Input a ticket name')
      return errors
    },
    startErrors() {
      const errors = []
      if (!this.$v.startDate.$dirty) return errors
      !this.$v.startDate.required && errors.push('Input a sales start date')
      return errors
    },
    endErrors() {
      const errors = []
      if (!this.$v.endDate.$dirty) return errors
      !this.$v.endDate.required && errors.push('Input a a sales end date')
      return errors
    },
    priceErrors() {
      const errors = []
      if (!this.$v.price.$dirty) return errors
      !this.$v.price.required && errors.push('Input a ticket price')
      return errors
    },
    qErrors() {
      const errors = []
      if (!this.$v.q.$dirty) return errors
      !this.$v.q.required && errors.push('Input a how many tickets are available')
      return errors
    },
  },
  created() {
    //do something after creating vue instance
    if (this.ticket!=undefined){
      // console.log('creaate teh adticket', 'this.ticket:', this.ticketId, "this.ticket.name",this.ticket.name);
      // console.log('do this');
      this.edit=true

      this.name= this.ticket.name
      this.price= this.ticket.price
      this.q=this.ticket.q
      if (this.ticket.start!=""){
        this.startDate= moment.tz(this.ticket.start,"Asia/Taipei").format("YYYY-MM-DD")
      }
      if (this.ticket.end!=""){
        this.endDate= moment.tz(this.ticket.end,"Asia/Taipei").format("YYYY-MM-DD")
      }
    }


  }



}
</script>

<style lang="scss" scoped>
</style>

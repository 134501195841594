<template>
<div id="">
  <Navbar></Navbar>
  <v-layout  wrap justify-space-around mt-5>
    <v-flex xs11 sm10 md8 lg6>
      <v-layout  wrap>
        <div class="">

          <h1 class="headline">{{myEvent.title}}</h1>
        </div>
        <div class="">
        </div>
      </v-layout>
      <v-layout  wrap class="dates" align-center>

        <span>{{myEvent.start | dateTime}}</span>
        <v-icon>arrow_right</v-icon>
        <span>{{myEvent.end | dateTime}}</span>
      </v-layout>

      <v-layout  wrap justify-center class=" mt-2 no-ma-sides">
        <v-flex xs4>
          <h3 class="title">Ticket type</h3>
          <br>
          <p>{{ticket.name}}</p>
          <div class="" v-if="this.couponVal!=0">
            <br>
            <p>Coupon: {{myCoupon.name}}</p>
          </div>
        </v-flex>
        <v-flex xs2 class="text-xs-right">
          <h3 class="title">Quantity</h3>
          <br>
          <p>{{this.number}}</p>
        </v-flex>
        <v-flex xs3 class="text-xs-right">
          <h3 class="title">Unit price</h3>
            <br>
            <p>{{ticket.price}} TWD</p>

        </v-flex>
        <v-flex xs3 class="text-xs-right">
          <h3 class="title">Price</h3>
          <br>
          <p>{{ticket.price*this.number}} TWD</p>
          <div class="" v-if="this.couponVal!=0">
            <br>
            <p>- {{couponVal}} TWD</p>
          </div>
        </v-flex>
      </v-layout>
      <v-divider></v-divider>
      <v-layout  wrap class="ma-3 no-ma-sides">
        <v-flex xs9 class='text-xs-right'>
          <h2 class="title">{{$t("paymentPage.totalToPay")}}</h2>
        </v-flex>
        <v-flex xs3 class='text-xs-right'>
          <h2 class="title">
            {{total}} TWD
          </h2>
        </v-flex>
        <v-flex xs12 class="text-xs-right pt-3">
          <span class="alert">{{alert}}</span>
        </v-flex>
      </v-layout>
      <v-layout v-if="total!=0"  wrap>
        <v-text-field
          v-model="couponCode"
          :error-messages="couponErr"
          label="Coupon"
        ></v-text-field>
        <v-btn color="rgb(255, 64, 0)" dark @click="checkCoupon">Check coupon</v-btn>
        <CardPayment :amount="total" :comment="myEvent.title+' - '+ticket.name" @success="success" @fail="fail"></CardPayment>
        <!-- <v-btn color="primary" @click="success">dont pay</v-btn> -->
      </v-layout>
      <v-layout  wrap v-else>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="success">Confirm Ticket</v-btn>
      </v-layout>
    </v-flex>
  </v-layout>

</div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'
import CardPayment from '../../shared/CardPayment.vue'
import AWSSES from '../../../aws-ses'
import config from '../../../config'
import firebase from '../../../FirebaseApp'
import {
  mapGetters,
} from 'vuex'
import * as types from '../../../store/types'

export default {
  name: "",
  data: () => ({
    admin:{
      email:'hello@myroomabroad.com',
      userName:'Sexy'
    },
    couponCode:"",
    couponVal:0,
    couponErr:[],
    myCoupon:{},
    alert:"",
  }),
  firebase() {
    return {
      myEvent: {
        source:firebase.database().ref('events/'+this.$route.params.eventId),
        asObject:true,
        readyCallback:function(){
          console.log('got it');
          dataLayer.push({
            eventId:this.$route.params.eventId,
          })
        }
      },
      // sold:{
      //   source:firebase.database().ref('tickets').orderByChild('eventId').equalTo(this.$route.params.eventId),
      //   readyCallback:function(){
      //     console.log('got it');
      //   }
      // },
      coupons:{
        source:firebase.database().ref('evCoupons').orderByChild("eventId").equalTo(this.$route.params.eventId),
        readyCallback:function(){
          let indexes = []
          console.log('checking MyTickets')
          // console.log(moment().isBefore(moment()), 'is now before now?');
          for (var i = 0; i < this.coupons.length; i++) {
            if (this.coupons[i].used==true){
              console.log('used');
              indexes.unshift(i)
            } else if (moment(this.coupons[i].expDate).isBefore(moment())){
              console.log('expired');
              indexes.unshift(i)
            }
          }
          for (var j = 0; i < indexes.length; j++) {
            this.coupons.splice(indexes[j],1)
          }
        }

      },
      myTickets:{
        source:firebase.database().ref('tickets').orderByChild("owner").equalTo(this.user.id),
        readyCallback:function(){
          console.log('checking MyTickets');
          for (var i = 0; i < this.myTickets.length; i++) {
            if (this.myTickets[i].eventId==this.$route.params.eventId){
              this.alert="You already have a ticket for this event!!!"
              console.log('you have a ticket');
            }
          }
        }
      }
    }
  },

  methods: {
    checkCoupon(){
      console.log('coupon');
      dataLayer.push({eventCouponCode:this.myCoupon})
      this.couponErr=[]
      let vm=this
      this.myCoupon = vm.coupons.find(function(el) {
        return el.name==vm.couponCode
      })
      console.log("this is the coupon",this.myCoupon);
      if (this.myCoupon==undefined){
        dataLayer.push({
          'event':'eventCouponInvalid',
        })
        this.couponErr.push('Coupon invalid')
      } else if (this.myCoupon.used==true){
        dataLayer.push({
          'event':'eventCouponUsed',
        })
        this.couponErr.push('Coupon has been used')
      } else if (moment(this.myCoupon.expDate).isBefore(moment())){
        dataLayer.push({
          'event':'eventCouponExpired',
        })
        this.couponErr.push('Coupon has Expired')
      } else {
        this.calcVal(this.myCoupon)
      }

    },
    calcVal(el){
      let n=1
      if (el.sUse==false){
        n=this.number
      }
      if (el.absVal==true){
        this.couponVal=n*parseInt(el.val)
      } else {
        this.couponVal=n*parseInt(el.val)/100*parseInt(this.ticket.price)
      }
      dataLayer.push({
        'event':'eventCoupon',
      })

    },
    sellTicket(){
      console.log('sold');
      let ticket={
        eventId:this.$route.params.eventId,
        type:this.$route.params.ticketId,
        owner:this.user.id,
        time:moment(),
        price:Math.round(this.total/this.number)
      }
      if (this.couponVal!=0 && this.couponVal!=undefined){
        ticket.coupon=this.myCoupon.name
        ticket.discount=this.couponVal
        if(this.myCoupon.sUse==true){
          axios.patch('evCoupons/'+this.myCoupon['.key']+'.json?auth='+ this.$store.state.auth.idToken,{used:true})
          .then(()=>{
            console.log('patched coupon');
          })
          .catch(err=>{
            console.log(err);
            throw "Error with Coupon"

          })
        }
      }
      let j=0
      for (var i = 0; i < this.number; i++) {
        axios.post('tickets.json?auth='+ this.$store.state.auth.idToken,ticket)
        .then(()=>{
          j++
          if (j==this.number){
            this.$router.push('/myTickets')
          }
        })
        .catch(err=>{
          console.log('Error: '+err)
          throw "Error with Ticket Creation"
        })
      }
      axios.patch('users/'+this.user.id+'.json?auth='+ this.$store.state.auth.idToken,{hasTicket:true})
      .catch(err=>{
        throw "Error with user"
        console.log('Error: '+err);
      })
    },
    success(){
      try {
        this.sellTicket()
      }
      catch(err){
        alert('Something Happened. Please Contact My Room Abroad Staff (Hello@myroomabroad.com). Give them this info: ',err)
      }
      dataLayer.push({
        'event':'ticketSale',
      })
      alert('Payment successful!')
      AWSSES.emailing(this.admin, 'ATicketSold')
      let info={
        url:this.myEvent.url
      }
      AWSSES.emailingInfo(this.user,info,'UTicketSold')
    },
    fail(){
      dataLayer.push({
        'event':'ticketSaleFailed',
      })
      alert('Payment Failed')
      // AWSSES.emailing(this.admin, 'APaymentFailed')
      AWSSES.emailing(this.user, 'TPaymentFailed')
    }
  },
  computed: {
    ...mapGetters({
      user: types.GET_USER
    }),
    ticket(){
      if (this.myEvent.tickets!= undefined){

        return this.myEvent.tickets[this.$route.params.ticketId]
      } else return ""
    },
    number(){
      let n=1
      if (this.$route.query.n!=undefined){
        n=parseInt(this.$route.query.n)
      }
      return n
    },
    total(){
      let t= this.number*(parseInt(this.ticket.price))-this.couponVal
      dataLayer.push({
        ticketPrice:t,
      })
      return t
    }
  },
  created() {
    //do something after creating vue instance
    console.log('do the created');


  },
  filters:{
    dateTime(t){
      return moment(t).format("ddd, LLL")
    }
  },
  components: {
    // Loading,
    CardPayment,
  }
}
</script>
<style lang="scss" scoped>
.dates{
  color:rgba(0,0,0,0.6)
}
.alert{
  color:red;
  font-weight: 500
}
</style>

<template lang="html">
  <div class="">
    <Navbar></Navbar>
    <v-container grid-list-xl>
      <v-layout  wrap justify-space-around>
        <v-flex xs12 sm10 md8>
          <h1>Add an event</h1>
          <v-text-field
            v-model="title"
            label="Title"
            :error-messages="titleErrors"
            :maxLength="64"
            @input="$v.title.$touch()" @blur="$v.title.$touch()"
          ></v-text-field>
          <v-textarea v-model="description" :error-messages="descriptionErrors" label="description" hint="Give a nice description of the event" required @input="$v.description.$touch()" @blur="$v.description.$touch()"></v-textarea>
          <v-textarea v-model="sdesc" :error-messages="sdescErrors" label="Short description" hint="Give a SHORT!!!!! description of the event" counter="500" required @input="$v.sdesc.$touch()" @blur="$v.sdesc.$touch()"></v-textarea>
          <v-text-field
            v-model="location"
            label="Location"
            :error-messages="locationErrors"
            @input="$v.location.$touch()" @blur="$v.location.$touch()"
          ></v-text-field>
          <v-layout wrap>
            <v-select
              :items="sports"
              v-model="sport"
              label="Select a sport (optional for decathlon upload)"
              return-object
              item-text="name"
            ></v-select>
          </v-layout>
          <v-layout wrap>
            <v-text-field
              v-model="url"
              label="Form URL"
              @input="$v.url.$touch()"
              @blur="$v.url.touch()"
            ></v-text-field>
          </v-layout>

          <v-layout  wrap align-center>

          <v-menu ref="sDateMenu" :close-on-content-click="false" v-model="sDateMenu" :nudge-right="40" :return-value.sync="sDate" lazy transition="scale-transition" offset-y full-width >
            <v-text-field :error-messages="sDateErrors" slot="activator" v-model="sDate" label="Start date" prepend-icon="event" readonly clearable @input="$v.sDate.$touch()" @blur="$v.sDate.$touch()"></v-text-field>
            <v-date-picker  v-model="sDate" @input="$refs.sDateMenu.save(sDate)"></v-date-picker>
          </v-menu>
          <v-menu
            ref="sTimeMenu"
            :close-on-content-click="false"
            v-model="sTimeMenu"
            :nudge-right="40"
            :return-value.sync="sTime"
            lazy
            transition="scale-transition"
            offset-y
          >
            <v-text-field
              slot="activator"
              v-model="sTime"
              label="Select the time"
              prepend-icon="access_time"
              readonly
              :error-messages="sTimeErrors"
            ></v-text-field>
            <v-time-picker
              v-if="sTimeMenu"
              v-model="sTime"
              format="24hr"
              landscape
              @change="$refs.sTimeMenu.save(sTime)"
            ></v-time-picker>
          </v-menu>
          <span>({{tz}})</span>
        </v-layout>
        <v-layout  wrap align-center>

        <v-menu ref="eDateMenu" :close-on-content-click="false" v-model="eDateMenu" :nudge-right="40" :return-value.sync="eDate" lazy transition="scale-transition" offset-y full-width >
          <v-text-field :error-messages="eDateErrors" slot="activator" v-model="eDate" label="End date" prepend-icon="event" readonly clearable @input="$v.eDate.$touch()" @blur="$v.eDate.$touch()"></v-text-field>
          <v-date-picker  v-model="eDate" @input="$refs.eDateMenu.save(eDate)"></v-date-picker>
        </v-menu>
        <v-menu
          ref="eTimeMenu"
          :close-on-content-click="false"
          v-model="eTimeMenu"
          :nudge-right="40"
          :return-value.sync="eTime"
          lazy
          transition="scale-transition"
          offset-y
        >
          <v-text-field
            slot="activator"
            v-model="eTime"
            label="Select the time"
            prepend-icon="access_time"
            readonly
            :error-messages="eTimeErrors"
          ></v-text-field>
          <v-time-picker
            v-if="eTimeMenu"
            v-model="eTime"
            format="24hr"
            landscape
            @change="$refs.eTimeMenu.save(eTime)"
          ></v-time-picker>
        </v-menu>
        <span>({{tz}})</span>
      </v-layout>
      <Dropzone id="photos" @onUpload="image.push($event)" :photos="image" :landlordId="'events'">
      </Dropzone>
      <div class="" v-if="imageErrors.length>0">
        {{imageErrors}}
      </div>
      <v-btn color="primary" :disabled="sending" @click="validate">Add Event</v-btn>
        </v-flex>
      </v-layout>
    </v-container>
  </div>

</template>

<script>
import moment from 'moment'
import axios from 'axios'
import Dropzone from '../../Dropzone.vue'
// import AWSSES from '../../../aws-ses'
import {
  required,
  maxLength,
  minLength
} from 'vuelidate/lib/validators'

export default {
  data: () => ({
    edit:false,
    loading:false,
    sTimeMenu:false,
    sDateMenu:false,
    eTimeMenu:false,
    sport:null,
    eDateMenu:false,
    title: "",
    sdesc:"",
    description: "",
    url:"",
    location:"",
    sDate: "",
    sTime: "00:00",
    eDate: "",
    eTime: "00:00",
    maxPeople:"",
    tz:'GMT+0800',
    image: [],
    sending:false,
    myEvent:{},
    sports:[
      {
        decathlonSport:282,
        name:"Road bike"
      },{
        decathlonSport:283,
        name:"City bike"
      },{
        decathlonSport:284,
        name:"Hybrid bike"
      },{
        decathlonSport:285,
        name:"Mountain bike"
      },{
        decathlonSport:443,
        name:"Trekking (multi day)"
      },{
        decathlonSport:458,
        name:"Hiking (Single day)"
      },{
        decathlonSport:186,
        name:"Diving/Snorkeling"
      },{
        decathlonSport:333,
        name:"Sea fishing"
      },{
        decathlonSport:51,
        name:"Canyoning/river tracing"
      },{
        decathlonSport:322,
        name:"Surfing"
      }
    ]
  }),
  validations: {
    title: {
      required,
      maxlength:maxLength(64)
    },
    description: {
      required
    },
    url:{
      required
    },
    sdesc: {
      required
    },
    sDate: {
      required
    },
    sTime: {
      required
    },
    eDate: {
      required
    },
    eTime: {
      required
    },
    location: {
      required
    },
    image:{
      required,
      maxLength:maxLength(1),
      minLength:minLength(1)
    },


  },
  methods:{
    addEvent(){
      let start=new Date(this.sDate+' ' +this.sTime +' '+this.tz)
      let end=new Date(this.eDate+' '+this.eTime+' '+this.tz)
      let newEvent={
        location:this.location,
        img:this.image,
        start:start,
        end:end,
        description:this.description,
        url:this.url,
        sdesc:this.sdesc,
        title:this.title,
      }
      if (this.sport){
        newEvent.sport=this.sport.name
        newEvent.decathlonSport=this.sport.decathlonSport
      }

      console.log('Add Event axios.post functions', newEvent);
      if (this.edit==false){
        axios.post('/events.json?auth='+this.$store.state.auth.idToken, newEvent)
        .then(()=>{
          console.log('success');
          this.$router.push('/events')
        })
        .catch((err)=>{
          console.log(err);
          this.sending=false
        })
      } else {
        axios.patch('/events/'+this.$route.query.id+'.json?auth='+this.$store.state.auth.idToken, newEvent)
        .then(()=>{
          console.log('success');
          this.$router.push('/eventMgmt/'+this.$route.query.id)
        })
        .catch(err=>{
          console.log(err);
          this.sending=false
        })
      }

    },
    validate() {
      // console.log('DateL:  ', sSubmit)
      this.sending=true
      this.$v.$touch()
      if (!this.$v.$invalid && !this.$v.$anyError) {
        this.addEvent()
      } else {
        this.$v
        this.sending=false
      }
    },

  },
  computed:{
    titleErrors() {
      const errors = []
      if (!this.$v.title.$dirty) return errors
      !this.$v.title.maxlength && errors.push('Limit your title to 64 characters')
      !this.$v.title.required && errors.push('Input a title')
      return errors
    },
    descriptionErrors() {
      const errors = []
      if (!this.$v.description.$dirty) return errors
      !this.$v.description.required && errors.push('Input a description')
      return errors
    },
    urlErrors() {
      const errors = []
      if (!this.$v.url.$dirty) return errors
      !this.$v.url.required && errors.push('ADD YOUR FORM')
      return errors
    },
    sdescErrors() {
      const errors = []
      if (!this.$v.sdesc.$dirty) return errors
      !this.$v.sdesc.required && errors.push('Input a SHORT!!! description')
      return errors
    },
    locationErrors() {
      const errors = []
      if (!this.$v.location.$dirty) return errors
      !this.$v.location.required && errors.push('Input a location')
      return errors
    },

    sDateErrors() {
      const errors = []
      if (!this.$v.sDate.$dirty) return errors
      !this.$v.sDate.required && errors.push('Add a Start Date')
      return errors
    },
    sTimeErrors() {
      const errors = []
      if (!this.$v.sTime.$dirty) return errors
      !this.$v.sTime.required && errors.push('Add a Start Time')
      return errors
    },
    eDateErrors() {
      const errors = []
      if (!this.$v.eDate.$dirty) return errors
      !this.$v.eDate.required && errors.push('Add a End Date')
      return errors
    },
    eTimeErrors() {
      const errors = []
      if (!this.$v.eTime.$dirty) return errors
      !this.$v.eTime.required && errors.push('Add a End Time')
      return errors
    },
    imageErrors(){
      const errors = []
      if (!this.$v.image.$dirty) return errors
      !this.$v.image.maxLength && errors.push('Add max 1 picture!')
      !this.$v.image.required && errors.push('Add a picture!')
      !this.$v.image.minLength && errors.push('Add min 1 picture!')

      return errors
    },


  },
  created() {
    //do something after creating vue instance
    if (this.$route.query.id!=undefined){
      this.loading=true
      this.edit=true
      axios.get('events/'+this.$route.query.id+'.json')
      .then(res=>{
        this.myEvent=res.data
        this.title= this.myEvent.title
        this.description= this.myEvent.description
        this.url=this.myEvent.url
        this.sdesc=this.myEvent.sdesc
        this.location=this.myEvent.location
        this.sDate= moment.tz(this.myEvent.start,"Asia/Taipei").format("YYYY-MM-DD")
        this.sTime= moment.tz(this.myEvent.start,"Asia/Taipei").format("HH:MM")
        this.eDate= moment.tz(this.myEvent.end,"Asia/Taipei").format("YYYY-MM-DD")
        this.eTime= moment.tz(this.myEvent.end,"Asia/Taipei").format("HH:MM")
        this.image=this.myEvent.img
        if (this.myEvent.decathlonSport){
          this.sport=this.sports.find(x=>x.decathlonSport==this.myEvent.decathlonSport)
        } else if (this.myEvent.sport) {
          this.sport=this.sports.find(x=>x.name==this.myEvent.sport)
        }
        this.loading=false
      })
    }
  },
  components:{
    Dropzone
  }
}
</script>

<style lang="scss" scoped>
</style>
